import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import './notice.css'

import i18n from '@/plugins/i18n'

const textConfirmButton = i18n.global.t ('messages.confirmButton')
const cancelButtonButton = i18n.global.t ('messages.cancelButton')

const cancelButtonColor= '#2dce89'

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    customClass: {
        popup: 'toast',
    },
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
})

const Alert = Swal.mixin({
    position: 'top',
    showCloseButton: true,
    confirmButtonText: 'OK',
})

const Confirm = Swal.mixin ({
    position: top,
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: textConfirmButton,
    cancelButtonText: cancelButtonButton,
    cancelButtonColor: cancelButtonColor,
})

const notice = {
    toast: {
        default: async (msg) =>{
            await Toast.fire({ icon: 'success', title: msg })
        },
        success: async (msg) => {
            await Toast.fire({ icon: 'success', title: msg })
        },
        error: async (msg) => {
            await Toast.fire({ icon: 'error', title: msg })
        },
        warning: async (msg) =>  {
            await Toast.fire({ icon: 'warning', title: msg })
        },
        info: async (msg) =>  {
            await Toast.fire({ icon: 'info', title: msg })
        }
    },
    alert: async (msg) =>  {
        await Alert.fire({ icon: 'warning', title: msg })
    },
    confirm: async (msg) =>  {
        return await Confirm.fire({ icon: 'warning', title: msg }).then((result) => {
            return result.isConfirmed
        })
    },

}
export default {
    install: (App) => {
        App.config.globalProperties.$notice = notice
    }
}


// import { useToast } from 'vue-toast-notification'
// import 'vue-toast-notification/dist/theme-sugar.css'
//
// const alertOptions = { queue: true, duration: 0, position: 'top' }
// const toastOptions = { duration: 5000, position: 'top-right' }
//
// const toast = useToast()
//
// const notice = {
//     alert: {
//         default (msg) {
//             return toast.default(msg, alertOptions)
//         },
//         success (msg) {
//             return toast.success(msg, alertOptions)
//         },
//         error (msg) {
//             return toast.error(msg, alertOptions)
//         },
//         warning (msg) {
//             return toast.warning(msg, alertOptions)
//         },
//         info (msg) {
//             return toast.info(msg, alertOptions)
//         }
//     },
//     toast: {
//         default (msg) {
//             return toast.default(msg, toastOptions)
//         },
//         success (msg) {
//             return toast.success(msg, toastOptions)
//         },
//         error (msg) {
//             return toast.error(msg, toastOptions)
//         },
//         warning (msg) {
//             return toast.warning(msg, toastOptions)
//         },
//         info (msg) {
//             return toast.info(msg, toastOptions)
//         },
//         dismissAll () {
//             toast.clear()
//             return this
//         },
//     },
//
// }
//
// export default {
//     install: (App) => {
//         App.config.globalProperties.$notice = notice
//     }
// }

