import { buildUrlParams } from '@/utils/urlUtils'
import AbstractResource from "@/resources/abstract.resource";

export default class MenuResource extends AbstractResource {
    constructor () {
        super()
    }

    async createMenu (menu) {
        try {
            return this.client.post('/menu', menu)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateMenu (menu) {
        try {
            return this.client.patch(`/menu/${menu.id}`, menu)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getTavernMenus (tavernId, params) {
        try {
            const taverns = await this.client.get(`/tavern/${tavernId}/menus`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteMenu (menuId) {
        try {
            await this.client.delete(`/menu/${menuId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}