<template>
  <nav class="navbar navbar-expand-md mt-0 green-background z-index-sticky">
    <div class="container ps-2 pe-0 ms-auto me-lg-6">
      <button
        id="show-navbar"
        class="shadow-none burger-menu navbar-toggler"
        type="button"
      >
        <mdicon
          name="menu"
          class="text-white"
        />
      </button>
      <div
        id="navigation"
        class="collapse navbar-collapse"
      >
        <TopNavBar />
      </div>
    </div>
  </nav>
</template>

<script>
import TopNavBar from "@/components/layout/navBar/TopNavBar.vue";
import {mapMutations} from "vuex";

export default {
  name: 'NavBar',
  components: { TopNavBar },
  props: {
    isBtn: { type: String, default: 'bg-gradient-light' },
    darkMode: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.green-background {
 background-color: #00b081;
}
.nav-link > .text-info {
  margin-right: 5px;
}
.space {
  width:50px;
}
.burger-menu {
  margin-left: -20px;
}
</style>
