import { createRouter, createWebHistory } from 'vue-router'

import SigninPage from '@/pages/signin/SigninPage.vue'
import AdminUsersPage from '@/pages/user/AdminUsersPage.vue'
import TavernsPage from '@/pages/tavern/TavernsPage.vue'
import MenusPage from '@/pages/menu/MenusPage.vue'
import MenuSectionsPage from '@/pages/menuSection/MenuSectionsPage.vue'
import MenuItemsPage from '@/pages/menuItem/MenuItemsPage.vue'
import ProfilePage from '@/pages/user/ProfilePage.vue'
import TagsPage from "@/pages/tag/TagsPage.vue";
import RecoverPassword from '@/pages/recoverPassword/RecoverPassword.vue'
import ResetPassword from '@/pages/resetPassword/ResetPassword.vue'
import TimeTablesPage from '@/pages/timeTable/TimeTablesPage.vue'
import store from '@/store'

const publicRoutes = [
  {
    path: '/',
    name: 'SignIn',
    component: SigninPage,
    meta: {
      skipAuth: true
    }
  },
  {
    path: '/request-reset',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: {
      skipAuth: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      skipAuth: true
    }
  },
]

const privateRoutes = [
  // {
  //   path: '/private/profile',
  //   name: 'Profile',
  //   component: profilePage,
  //   meta: {
  //     needsAuth: true
  //   }
  // },
  // {
  //   path: '/private/signup',
  //   name: 'PrivateSignupPage',
  //   component: PrivateSignupPage,
  //   meta: {
  //     skipAuth: true
  //   }
  // },
  {
    path: '/private/user/:userId/profile',
    name: 'PrivateProfilePage',
    component: ProfilePage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/private/user/:userId/taverns',
    name: 'PrivateTavernsPage',
    component: TavernsPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/private/tavern/:tavernId/time-tables',
    name: 'PrivateTimeTablePage',
    component: TimeTablesPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/private/tavern/:tavernId/menus',
    name: 'PrivateMenusPage',
    component: MenusPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/private/menu/:menuId/sections',
    name: 'PrivateMenuSectionsPage',
    component: MenuSectionsPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/private/section/:menuSectionId/items',
    name: 'PrivateMenuItemsPage',
    component: MenuItemsPage,
    meta: {
      skipAuth: false
    },
  }
]

const adminRoutes = [
  {
    path: '/admin/users',
    name: 'AdminUsersPage',
    component: AdminUsersPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/user/:userId/taverns',
    name: 'AdminUserTavernsPage',
    component: TavernsPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/taverns',
    name: 'AdminTavernsPage',
    component: TavernsPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/tavern/:tavernId/time-tables',
    name: 'AdminTimeTablesPage',
    component: TimeTablesPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/tavern/:tavernId/menus',
    name: 'AdminMenusPage',
    component: MenusPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/menu/:menuId/sections',
    name: 'AdminMenuSectionsPage',
    component: MenuSectionsPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/section/:menuSectionId/items',
    name: 'AdminMenuItemsPage',
    component: MenuItemsPage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/user/:userId/profile',
    name: 'AdminProfilePage',
    component: ProfilePage,
    meta: {
      skipAuth: false
    },
  },
  {
    path: '/admin/tags',
    name: 'AdminTagsPage',
    component: TagsPage,
    meta: {
      skipAuth: false
    },
  },
  // {
  //   path: '/admin/menu-item/:menuItemId/tags',
  //   name: 'MenuItemTags',
  //   component: MenuItemTagsPage,
  //   meta: {
  //     skipAuth: false
  //   },
  // },
]

const routes = [ ...publicRoutes, ...privateRoutes, ...adminRoutes ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
})

router.beforeEach(async (to, from) => {
  if (!to.meta.skipAuth && !store.getters.isLogged) {
    window.location.href = '/'
    return false
  }
  return true
})

export default router
