import AbstractResource from '@/resources/abstract.resource'

export default class ContactResource extends AbstractResource {
    constructor () {
        super()
    }
    async createContact (contact) {
        try {
            return this.client.post('/contact', contact)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateContact (contact) {
        try {
            return this.client.patch(`/contact/${contact.id}`, contact)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getTavernContact (tavernId) {
        try {
            const contact = await this.client.get(`/tavern/${tavernId}/contact`)
            return contact.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteContact (contactId) {
        try {
            await this.client.delete(`/contact/${contactId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}