<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <UpsertTavern
        v-if="showUpsert"
        :tavern="tavern"
        :user-id="userId"
        @create-tavern="createTavern"
        @update-tavern="updateTavern"
        @hide-forms="hideForms"
      />
      <TavernContact
        v-if="showContact"
        :tavern-id="tavernId"
        @hide-forms="hideForms"
      />
      <TavernQR
        v-if="showQR"
        :tavern="tavern"
        @hide-forms="hideForms"
      />
    </div>
    <div class="row">
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <div class="col d-flex justify-content-start">
              <h6 class="mb-2">
                {{ pageTitle() }}
              </h6>
            </div>
            <div class="col d-flex align-items-center mr-4">
              <button
                v-if="userId && !(showUpsert || showContact)"
                class="btn mb-0 btn-success ms-auto"
                @click="enableUpsert"
              >
                <mdicon name="store-plus-outline" />
                {{ $t('messages.createTavern') }}
              </button>
            </div>
          </div>
        </div>
        <contentPlaceholder v-if="loading" />
        <div
          v-else
          class="table"
        >
          <div class="table-row">
            <div class="px-2 py-1 row">
              <div class="col">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ $t('messages.tavernName') }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div
              v-for="currentTavern in taverns"
              :key="currentTavern.id"
            >
              <div class="table-row">
                <div class="px-2 py-1 row">
                  <div class="row">
                    <div class="col d-flex">
                      <h6 class="mb-0 text-sm">
                        {{ currentTavern.title }}
                      </h6>
                    </div>
                    <div class="col d-flex justify-content-end mt-1">
                      <a
                        v-if="admin"
                        class="btn btn-link text-success"
                        :href="`/${baseDir}/user/${currentTavern.userId}/profile`"
                        :title="$t('messages.userData')"
                      >
                        <mdicon name="account-outline" />
                      </a>
                      <a
                        class="btn btn-link text-danger"
                        :title="$t('messages.deleteTavern')"
                        @click="deleteTavern(currentTavern)"
                      >
                        <mdicon name="trash-can-outline" />
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col d-flex justify-content-end mt-1 overflow-y-visible">
                      <a
                        class="btn btn-link text-success"
                        :title="$t('messages.editTavern')"
                        @click="selectItem(currentTavern)"
                      >
                        <mdicon name="store-edit-outline" />
                      </a>
                      <a
                        class="btn btn-link text-success"
                        :href="`/${baseDir}/tavern/${currentTavern.id}/menus`"
                        :title="$t('messages.seeMenus')"
                      >
                        <mdicon name="notebook-outline" />
                      </a>
                      <a
                        class="btn btn-link text-success"
                        :href="`/${baseDir}/tavern/${currentTavern.id}/time-tables`"
                        :title="$t('messages.timeTables')"
                      >
                        <mdicon name="timetable" />
                      </a>
                      <a
                        class="btn btn-link text-success"
                        :title="$t('messages.contact')"
                        @click="enableContact(currentTavern)"
                      >
                        <mdicon name="map-marker-outline" />
                      </a>
                      <a
                        class="btn btn-link text-success"
                        :title="$t('messages.qr')"
                        @click="enableTavernQR(currentTavern)"
                      >
                        <mdicon name="qrcode" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPagination"
        v-model="paging.page"
        :loading="loading"
        :total-rows="total"
        :per-page="paging.limit"
        class="mt-3 mb-3"
        :value="paging.page"
        @set-page="setPage"
      />
    </div>
  </div>
</template>

<script>
import ContentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import UpsertTavern from '@/views/components/tavern/UpsertTavern.vue'
import TavernContact from '@/views/components/tavern/TavernContact.vue'
import Pagination from '@/components/common/Pagination.vue'
import Tavern from '@/models/tavern'
import TavernResource from '@/resources/tavern.resource'
import TavernQR from '@/views/components/tavern/TavernQR.vue'

export default {
  name: "TavernList",
  components: {
    TavernQR,
    ContentPlaceholder,
    UpsertTavern,
    TavernContact,
    Pagination
  },
  data () {
    return {
      loading: true,
      showUpsert: false,
      showContact: false,
      showQR: false,
      taverns: null,
      tavernId: null,
      total: 0,
      tavern: null,
      userId: null,
      paging: { page: 1, limit: 20, sort: 'createdAt', sortType: 'desc' },
      showPagination: false,
      tavernResource: new TavernResource(),
    }
  },
  mounted () {
    this.userId = this.$route.params?.userId ?  this.$route.params?.userId : null
    this.selectItems()
  },
  methods: {
    async selectItems () {
      if (this.userId) {
        await this.getUserTaverns()
        return
      }
      if (this.isAdminUser()) {
        await this.getTaverns()
      }
    },
    pageTitle () {
      if (!this.userId) {
        return this.$t('messages.taverns')
      }
      if (!this.admin) {
        return this.$t('messages.myTaverns')
      }
      return this.$t('messages.userTaverns')
    },
    async getTaverns () {
      const response = await this.tavernResource.getTaverns(this.paging)
      if (response) {
        this.loading = false
        this.taverns = response.data
        this.total = response.total
        if (this.total > this.paging.limit) {
          this.showPagination = true
        }
      }
    },
    async getUserTaverns () {
      const response = await this.tavernResource.getUserTaverns(this.userId, this.paging)
      if (response) {
        this.loading = false
        this.taverns = response.data
        this.total = response.total
        if (this.total > this.paging.limit) {
          this.showPagination = true
        }
      }
    },
    async createTavern (tavernData) {
      try {
        const response = await this.tavernResource.createTavern(tavernData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'))
          return
        }
        this.appendItemOnList(response.data)
      } catch (e) {
        if (e.status === 409) {
          await this.$notice.alert(this.$t('messages.tavernErrorMessage'))
          return
        }
        await this.$notice.alert(this.$t('messages.errorMessage'))
      }
    },
    async updateTavern (tavernData) {
      try {
        const response = await this.tavernResource.updateTavern(tavernData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.tavernErrorMessage'))
          return
        }
        await this.$notice.toast.info(this.$t('messages.updateMessage'))
        this.updateItemOnList(response.data)
      } catch (e) {
        if (e.status === 409) {
          await this.$notice.alert(this.$t('messages.tavernErrorMessage'))
          return
        }
        await this.$notice.alert(this.$t('messages.errorMessage'))
      }
    },
    async deleteTavern (tavern) {
      await this.$notice.confirm(this.$t('messages.areYouSure')).then(async (res) => {
        if (res) {
          try {
            const response = await this.tavernResource.deleteTavern(tavern.id)
            if (!response) {
              await this.$notice.alert(this.$t('messages.errorMessage'))
            }
            await this.$notice.toast.success(this.$t('messages.deletedMessage'));
            this.removeItemFromList(tavern)
          } catch (e) {
            await this.$notice.alert(this.$t('messages.errorMessage'))
          }
        }
      })
    },
    selectItem (tavern) {
      this.tavern = null
      this.tavern = new Tavern(tavern)
      this.hideForms()
      this.showUpsert = true
    },
    enableContact (tavern) {
      this.tavernId = tavern.id
      this.hideForms()
      this.showContact = true
    },
    async enableTavernQR (tavern) {
      this.tavern = new Tavern(tavern)
      this.hideForms()
      if (!tavern.slug) {
        await this.$notice.alert(this.$t('messages.mustHaveSlug'))
        return
      }
      this.showQR = true
    },
    appendItemOnList (newTavern) {
      this.taverns.unshift(newTavern)
      this.hideForms()
    },
    removeItemFromList (tavern) {
      this.taverns = this.taverns.filter((item) => item.id !== tavern.id)
      this.hideForms()
    },
    updateItemOnList (tavern) {
      this.taverns.find((item, index) => {
        if (item.id === tavern.id) {
          this.taverns[index] = tavern
        }
      })
      this.hideForms()
    },
    enableUpsert () {
      this.tavern = new Tavern()
      this.hideForms()
      this.showUpsert = true
    },
    hideForms () {
      this.showUpsert = false
      this.showContact = false
      this.showQR = false
    },
    setPage (page) {
      this.paging.page = page
      this.selectItems()
    }
  }
};
</script>

<style scoped>
.table :not(:first-child) {
  border: none;
}
.table-row {
  border-bottom: 1px solid #D5D8DC !important;
}
.table-row:hover {
  background-color: #E5E4E2;
}
.col > .btn {
  margin-bottom: 0;
}
</style>
