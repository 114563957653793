<template>
  <RecoverPasswordForm
    v-if="!isLogged()"
  />
</template>

<script>

import RecoverPasswordForm from '@/views/components/recoverPassword/RecoverPasswordForm.vue'

export default {
  name: 'RecoverPassword',
  components: { RecoverPasswordForm }
}
</script>
