import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { UserType } from '@/utils/enums/userEnum'

export default createStore({
  plugins: [createPersistedState()],

  state: {
    user: null,
    token: null
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setToken (state, token) {
      state.token = token
    },
    unsetUser (state) {
      state.user = null
    },
    unsetToken (state) {
      state.token = null
    },
    signOut (state) {
      state.user = null
      state.token = null
    }
  },
  actions: {
  },
  getters: {
    getToken (state) {
      return state.token
    },
    getUser (state) {
      return state.user
    },
    getUserId (state) {
      return state.user?.id
    },
    isLogged (state) {
      return !!state.token
    },
    isUser (state) {
      return state.user?.type === UserType.user
    },
    isAdmin (state) {
      return state.user?.type === UserType.admin
    }
  }
})
