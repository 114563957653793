import {buildUrlParams} from '@/utils/urlUtils'
import AbstractResource from '@/resources/abstract.resource'

export default class adminTavernResource extends AbstractResource {
    constructor () {
        super()
    }
    async createTavern (tavern) {
        try {
            return this.client.post('/tavern', tavern)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateTavern (tavern) {
        try {
            return this.client.patch(`/tavern/${tavern.id}`, tavern)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteTavern (tavernId) {
        try {
            await this.client.delete(`/tavern/${tavernId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getUserTaverns (userId, params) {
        try {
            const taverns = await this.client.get(`/user/${userId}/taverns`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getTaverns(params) {
        try {
            const users = await this.client.get('/tavern/', { params: buildUrlParams(params) })
            return {data: users.data.data, total: users.data.total}
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
