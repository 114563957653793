import {buildUrlParams} from '@/utils/urlUtils'
import AbstractResource from '@/resources/abstract.resource'

class UserResource extends AbstractResource {
    constructor () {
        super()
    }

    async createUser(user) {
        try {
            return this.client.post('/auth/signup', user)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateUser(user) {
        try {
            return this.client.patch(`/user/${user.id}`, user)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteUser(userId) {
        try {
            return this.client.delete(`/user/${userId}`)
        } catch (error) {
            console.warn(error)
            return null
        }
    }


    async getUser(userId) {
        try {
            const response = await this.client.get(`/user/${userId}`)
            return response.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getUsers(params) {
        try {
            const users = await this.client.get('/user/', {params: buildUrlParams(params)})
            return {data: users.data.data, total: users.data.total}
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async signin(login) {
        try {
            const response = await this.client.post('/auth/signin', login)
            return response.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async recoverPassword (request) {
        try {
            await this.client.post('/auth/reset-password-request', request)
            return 'messages.successMessage'
        } catch (error) {
            if (error.response.status === 409) {
                return 'messages.pendingRequest'
            }
            console.warn(error.response)
            return null
        }
    }

    async resetPassword (request) {
        try {
            await this.client.post('/auth/reset-password', request)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}

export default UserResource
