import {mapActions, mapGetters, mapMutations} from 'vuex'

const GlobalMixin = {
    data () {
        return {
            loggedUserId: null,
            admin: false,
            logged: false,
            baseDir: null
        }
    },
    mounted () {
        this.logged = this.isLogged()
        this.admin = this.isAdmin()
        this.baseDir = this.getBasePath()
        const user = this.getUser()
        this.loggedUserId = user ? user.id : null
    },
    methods: {
        ...mapGetters(['isAdmin', 'isUser', 'isLogged', 'getUser', 'getUserId']),
        ...mapMutations(['unsetUser', 'unsetToken']),
        getBasePath () {
            if (!this.logged) {
                this.resetUser()
                return
            }
            return this.admin ? 'admin' : 'private'
        },
        isAdminUser () {
            return this.isAdmin()
        },
        isNormalUser () {
            return this.isUser()
        },
        resetUser () {
            this.unsetUser()
            this.unsetToken()
        },
        logOut () {
            this.resetUser()
            window.location.href = '/'
        }
    }
}
export default GlobalMixin
