import { buildUrlParams } from '@/utils/urlUtils'
import AbstractResource from "@/resources/abstract.resource";

export default class MenuItemResource extends AbstractResource {
    constructor () {
        super()
    }
    async createMenuItem (menuItem) {
        try {
            return this.client.post('/menu-item', menuItem)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateMenuItem (menuItem) {
        try {
            menuItem.price = menuItem.price ? menuItem.price.toString() !== '' ? menuItem.price.toString() : null: null
            return this.client.patch(`/menu-item/${menuItem.id}`, menuItem)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getMenuSectionMenuItems (menuSectionId, params) {
        try {
            const taverns = await this.client.get(`/menu-section/${menuSectionId}/menu-items`, { params: buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteMenuItem (menuItemId) {
        try {
            await this.client.delete(`/menu-item/${menuItemId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
