<template>
  <aside class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-md fixed-start ms-3 bg-white">
    <div class="sidenav-header">
      <div id="sidenav-collapse-main">
        <i
          id="iconSidenav"
          class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
          aria-hidden="true"
        />
        <div>
          <span
            id="close-menu"
            class="g-sidenav-show d-none close-button "
          >
            <mdicon
              name="close"
              class="text-black-30"
            />
          </span>
          <router-link
            class="ps-4 pe-auto"
            to="/"
          >
            <img
              src="@/assets/img/taverno.png"
              class="img-logo mt-3"
              alt="main_logo"
            >
          </router-link>
        </div>
        <hr class="mt-0 horizontal dark">
        <AdminSideNav v-if="admin" />
        <PrivateSideNav v-else />
        <ul class="navbar-nav">
          <li class="mt-3 nav-item">
            <hr>
          </li>
          <li class="nav-item">
            <SideNavItem
              url="/"
              :nav-text="$t('messages.signOut')"
              class="text-danger"
              @click="logOut"
            >
              <template #icon>
                <mdicon
                  name="power"
                  class="text-danger"
                />
              </template>
            </SideNavItem>
          </li>
        </ul>
      </div>
    </div>
  </aside>
</template>
<script>
import SideNavItem from '@/components/layout/sideNav/SideNavItem.vue'
import PrivateSideNav from '@/components/layout/sideNav/PrivateSideNav.vue'
import AdminSideNav from '@/components/layout/sideNav/AdminSideNav.vue'

export default {
  name: 'SideNav',
  components: {
    PrivateSideNav,
    AdminSideNav,
    SideNavItem
  }
}
</script>

<style>
.img-logo {
  padding: 5px;
  height: 50px;
}
.close-button {
  margin-right: 5px;
  margin-top: 2px;
  float:right;
}
</style>
